import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueRouter from "vue-router";
import router from "./router";
import "./assets/css/reset.css";
import "./assets/css/common.scss";
import "./assets/css/markdown.scss";
import "./assets/css/github-markdown-light.css";
import ProductTabs from '../src/views/product-center/Tabs/Tabs.vue'

Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.component('ProductTabs', ProductTabs);
Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
