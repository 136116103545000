<template>
    <div class="detail">
        <div class="banner-box">
            <div class="banner">
                <el-carousel indicator-position="outside" arrow="always" height="442rem">
                    <el-carousel-item class="carousel-item">
                        <img src="./images/1.jpg"/>
                    </el-carousel-item>
                    <el-carousel-item class="carousel-item">
                        <img src="./images/2.jpg"/>
                    </el-carousel-item>
                    <el-carousel-item class="carousel-item">
                        <img src="./images/3.jpg"/>
                    </el-carousel-item>
                    <el-carousel-item class="carousel-item">
                        <img src="./images/4.jpg"/>
                    </el-carousel-item>
                    <el-carousel-item class="carousel-item">
                        <img src="./images/5.jpg"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="info">
                <div class="title">E20W云标签打印机</div>
                <div class="sub-title">WIFI+手动撕纸</div>
                <div class="text">通讯接口：WIFI、蓝牙</div>
                <div class="text">打印速度：100MM/S</div>
                <div class="text">打印宽度：56MM</div>
                <div class="text">适用：电商、物流等打印标签</div>
                <!--                <div class="price">官网零售价：¥ 399</div>-->
                <div
                    class="btn"
                    style="display:none;"
                    @click="
                        openTaobao(
                            'https://detail.tmall.com/item.htm?spm=a1z10.3-b-s.w4011-21228571373.94.1c456263SV0E2F&id=597836071265&rn=059417eeb73f7f2aaae5113831f29876&abbucket=14&skuId=4705793435175'
                        )
                    "
                >
                    了解更多
                </div>
            </div>
        </div>
        <ProductTabs>
            <template v-slot:1>
                <div class="content-bg1"></div>
                <div class="content-bg2"></div>
                <div class="content-bg3"></div>
                <div class="content-bg4"></div>
                <div class="content-bg5"></div>
                <div class="content-bg6"></div>
                <div class="content-bg7"></div>
                <div class="content-bg8"></div>
                <div class="content-bg9"></div>
            </template>
            <template v-slot:2>
                <img style="display: block;width: 1300rem;margin: 0 auto;" src="./images/E20W.jpeg" alt="">
            </template>
        </ProductTabs>
    </div>
</template>

<script>
export default {
    name: "E20W",
    data() {
        return {};
    },
    methods: {
        openTaobao(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
.detail {
    padding-top: 172rem;

    .banner-box {
        padding: 32rem 0;
        width: 1300rem;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .banner {
            width: 784rem;
            height: 500rem;

            .carousel-item {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img,
            video {
                height: 442rem;
            }
        }

        .info {
            width: 30%;

            .title {
                margin-top: 64rem;
                font-size: 22rem;
                font-weight: 900;
            }

            .sub-title {
                margin: 32rem 0 64rem 0;
                font-size: 16rem;
            }

            .text {
                margin-top: 8rem;
                font-size: 14rem;
            }

            .price {
                margin-top: 48rem;
                font-size: 22rem;
                font-weight: 900;
            }

            .btn {
                display: inline-block;
                padding: 12rem 16rem;
                margin-top: 24rem;
                font-size: 16rem;
                background: #ff6932;
                border-radius: 12rem;
                color: #fff;
                cursor: pointer;
            }
        }
    }

    .content-bg1 {
        height: 1812rem;
        background: url("./images/_01.png") center / 1920rem no-repeat;
    }

    .content-bg2 {
        height: 692rem;
        background: url("./images/_02.png") center / 1920rem no-repeat;
    }

    .content-bg3 {
        height: 1717rem;
        background: url("./images/_03.png") center / 1920rem no-repeat;
    }

    .content-bg4 {
        height: 1556rem;
        background: url("./images/_04.png") center / 1920rem no-repeat;
    }

    .content-bg5 {
        height: 2077rem;
        background: url("./images/_05.png") center / 1920rem no-repeat;
    }

    .content-bg6 {
        height: 1527rem;
        background: url("./images/_06.png") center / 1920rem no-repeat;
    }

    .content-bg7 {
        height: 1357rem;
        background: url("./images/_07.png") center / 1920rem no-repeat;
    }

    .content-bg8 {
        height: 1157rem;
        background: url("./images/_08.png") center / 1920rem no-repeat;
    }

    .content-bg9 {
        height: 2480rem;
        background: url("./images/_09.png") center / 1920rem no-repeat;
    }
}
</style>
