import VueRouter from "vue-router";

import home from "./views/home/index.vue";
import cloudPrinter from "./views/cloudPrinter/index.vue";
import printerList from "./views/printerList/index.vue";
import solution from "./views/solution/index.vue";
import customerCase from "./views/customerCase/index.vue";
import customerCaseDetail from "./views/customerCase/detail.vue";
import openDev from "./views/openDev/index.vue";
import about from "./views/about/index.vue";

import DP158 from "./views/product-center/WiFi/DP158/DP158.vue";
import DP358 from "./views/product-center/WiFi/DP358/DP358.vue";
import KM118 from "./views/product-center/WiFi/KM118/KM118.vue";
import DP458 from "./views/product-center/4G/DP458/DP458.vue";
import SX320 from "./views/product-center/4G/SX320/SX320.vue";
import UL410 from "./views/product-center/label/UL410/UL410.vue";
import SL410 from "./views/product-center/label/SL410/SL410.vue";
import E20W from "./views/product-center/label/E20W/E20W.vue";
import SC318 from "./views/product-center/faceSheet/SC318/SC318.vue";
import SL310 from "./views/product-center/faceSheet/SL310/SL310.vue";
import KM218MGD from "./views/product-center/faceSheet/KM218MGD/KM218MGD.vue";
import KM202MG from "./views/product-center/faceSheet/KM202MG/KM202MG.vue";
import DP058E from "./views/product-center/ticket/DP058E/DP058E.vue";

export const routes = [
    {
        path: "/home",
        name: "首页",
        component: home,
    },
    {
        path: "/cloudPrinter",
        name: "云打印",
        component: cloudPrinter,
    },
    {
        path: "/printerList",
        name: "云打印机",
        component: printerList,
    },
    /*{
        path: "/solution",
        name: "解决方案",
        component: solution,
    },*/
    {
        path: "/customerCase",
        name: "客户案例",
        component: customerCase,
    },
    {
        path: "/customerCaseDetail",
        name: "客户案例",
        component: customerCaseDetail,
        hidden: true
    },
    {
        path: "/openDev",
        name: "对接文档",
        component: openDev,
    },
    {
        path: "/productCenter/WiFi/DP158",
        name: "DP158小票机",
        component: DP158,
        hidden: true
    },
    {
        path: "/productCenter/WiFi/DP358",
        name: "DP358小票机",
        component: DP358,
        hidden: true
    },
    {
        path: "/productCenter/WiFi/KM118",
        name: "KM118系列云标签机",
        component: KM118,
        hidden: true
    },
    {
        path: "/productCenter/4G/DP458",
        name: "DP458云小票机",
        component: DP458,
        hidden: true
    },
    {
        path: "/productCenter/4G/SX320",
        name: "SX320系列云面单机",
        component: SX320,
        hidden: true
    },
    {
        path: "/productCenter/label/UL410",
        name: "UL410便携式热敏打印机",
        component: UL410,
        hidden: true
    },
    {
        path: "/productCenter/label/SL410",
        name: "SL410便携式热敏打印机",
        component: SL410,
        hidden: true
    },
    {
        path: "/productCenter/label/E20W",
        name: "E20W云标签打印机",
        component: E20W,
        hidden: true
    },
    {
        path: "/productCenter/faceSheet/SC318",
        name: "菜鸟裹裹x快麦SC318云打印机",
        component: SC318,
        hidden: true
    },
    {
        path: "/productCenter/faceSheet/SL310",
        name: "菜鸟裹裹x快麦SL310便携云打印机",
        component: SL310,
        hidden: true
    },
    {
        path: "/productCenter/faceSheet/KM218MGD",
        name: "拼多多x快麦KM-218MGD云打印机",
        component: KM218MGD,
        hidden: true
    },
    {
        path: "/productCenter/faceSheet/KM202MG",
        name: "快麦KM-202MG云标签机",
        component: KM202MG,
        hidden: true
    },
    {
        path: "/productCenter/ticket/DP058E",
        name: "饿么了x快麦DP058E云小票机",
        component: DP058E,
        hidden: true
    },
    {
        path: "/about",
        name: "关于我们",
        component: about,
        hidden: true
    },
];

const router = new VueRouter({
    routes: [
        {
            path: "/",
            redirect: "/home",
        },
        ...routes,
    ],
});

export default router;
