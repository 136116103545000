<template>
    <div class="tabs">
        <div class="tabs_row">
            <div class="tab_item tab_item1" :class="{selected: tabSelected === 1}" @click="()=>changeTabs(1)"></div>
            <div class="tab_item tab_item2" :class="{selected: tabSelected === 2}" @click="()=>changeTabs(2)"></div>
        </div>
        <slot v-if="tabSelected === 1" name="1"></slot>
        <slot v-else-if="tabSelected === 2" name="2"></slot>
    </div>
</template>

<script>
export default {
    name: "ProductTabs",
    data() {
        return {
            tabSelected: 1
        };
    },
    mounted() {
    },
    methods: {
        changeTabs(val) {
            this.tabSelected = val
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs {
    padding: 16rem 0;
}

.tabs_row {
    padding-bottom: 50rem;
    width: 1300rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .tab_item {
        cursor: pointer;

        &.tab_item1 {
            width: 252rem;
            height: 57rem;
            background: url("./images/03.png") center / 252rem no-repeat;
        }

        &.tab_item2 {
            width: 255rem;
            height: 57rem;
            background: url("./images/01.png") center / 255rem no-repeat;
        }

        &.selected {
            &.tab_item1 {
                width: 255rem;
                height: 72rem;
                background: url("./images/02.png") center / 255rem no-repeat;
            }

            &.tab_item2 {
                width: 256rem;
                height: 72rem;
                background: url("./images/04.png") center / 256rem no-repeat;
            }
        }
    }
}
</style>
